import { map, pick } from "lodash-es"
import { i18n } from "@/config"

const USER_ACTION_LOG_COMMON_TYPES = [
  {
    id: "shop_matching",
    name: i18n.t("logs.user_action_log.types.filters.shop_matching")
  },
  {
    id: "ota_account_changes",
    name: i18n.t("logs.user_action_log.types.filters.ota_account_changes")
  },
  {
    id: "inventory_setting",
    name: i18n.t("logs.user_action_log.types.filters.inventory_setting")
  }
]

export const buildInitiatorsParams = list => map(list, el => pick(el, ["_skip", "id", "type", "_only_by_type"]))

export const buildUserActionLogTypes = (sharedInventoryEnabled, isNicolasEnabled) => {
  const sharedInventoryEnabledTypes = [
    {
      id: "inventory_group",
      name: i18n.t("logs.user_action_log.types.filters.inventory_group")
    },
    {
      id: "inventory_group_car_classes_matching",
      name: i18n.t("logs.user_action_log.types.filters.inventory_group_car_classes_matching")
    }
  ]

  const sharedInventoryDiasbledTypes = [
    {
      id: "shop_car_classes_matching",
      name: i18n.t("logs.user_action_log.types.filters.shop_car_classes_matching")
    }
  ]

  const nicolasEnabledTypes = [
    {
      id: "shop_sources_cars_matching",
      name: i18n.t("logs.user_action_log.types.filters.shop_sources_cars_matching")
    }
  ]

  const nicolasDisabledTypes = [
    {
      id: "car_class_matching",
      name: i18n.t("logs.user_action_log.types.filters.car_class_matching")
    }
  ]

  const filteredBySharedInventoryTypes = sharedInventoryEnabled ? sharedInventoryEnabledTypes : []
  const filteredByNicolasTypes = isNicolasEnabled ? nicolasEnabledTypes : nicolasDisabledTypes
  const filteredTypes = sharedInventoryEnabled || isNicolasEnabled ? [] : sharedInventoryDiasbledTypes

  return [
    ...USER_ACTION_LOG_COMMON_TYPES,
    ...filteredByNicolasTypes,
    ...filteredBySharedInventoryTypes,
    ...filteredTypes
  ]
}
